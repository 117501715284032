import Swiper, { Navigation } from "swiper";

const LINKS = document.querySelectorAll(".menu__link");

LINKS.forEach(link => {
  link.addEventListener("click", e => {
    e.preventDefault();
    const ID = link.getAttribute("href");
    setTimeout(() => {
      document.body.classList.remove("hidden");
    }, 500);
    if (ID.split("#")[1]) {
      document.querySelector(ID).scrollIntoView({
        behavior: "smooth",
        block: "start"
      });
    } else {
      window.location.replace(ID);
    }
  });
});
const BTN_TEXT = document.querySelectorAll(".news-item__show-more");
const DOTS = document.querySelectorAll(".dots");
const MORE_TEXT = document.querySelectorAll(".more");
if (BTN_TEXT) {
  for (let i = 0; i < BTN_TEXT.length; i++) {
    BTN_TEXT[i].addEventListener("click", () => {
      if (DOTS[i].style.display !== "none") {
        DOTS[i].style.display = "none";
        MORE_TEXT[i].style.display = "inline";
        BTN_TEXT[i].style.display = "none";
      }
    });
  }
}

const WORKS_SLIDER = document.querySelector(".works-slider");
if (WORKS_SLIDER && window.innerWidth <= 575) {
  Swiper.use([Navigation]);
  new Swiper(WORKS_SLIDER, {
    slidesPerView: 1,
    observer: true,
    observeParents: true,
    spaceBetween: 15,
    navigation: {
      nextEl: ".works-slider-button.swiper-button-next",
      prevEl: ".works-slider-button.swiper-button-prev"
    },
    pagination: {
      el: ".works-slider-pagination",
      type: "bullets",
      clickable: !0
    }
  });
}

class CountdownTimer {
  constructor(deadline, cbChange, cbComplete) {
    this._deadline = deadline;
    this._cbChange = cbChange;
    this._cbComplete = cbComplete;
    this._timerId = null;
    this._out = {
      days: "",
      hours: "",
      minutes: "",
      seconds: "",
      daysTitle: "",
      hoursTitle: "",
      minutesTitle: "",
      secondsTitle: ""
    };
    this._start();
  }
  static declensionNum(num, words) {
    return words[
      num % 100 > 4 && num % 100 < 20
        ? 2
        : [2, 0, 1, 1, 1, 2][num % 10 < 5 ? num % 10 : 5]
    ];
  }
  _start() {
    this._calc();
    this._timerId = setInterval(this._calc.bind(this), 1000);
  }
  _calc() {
    const diff = this._deadline - new Date();
    const days = diff > 0 ? Math.floor(diff / 1000 / 60 / 60 / 24) : 0;
    const hours = diff > 0 ? Math.floor(diff / 1000 / 60 / 60) % 24 : 0;
    const minutes = diff > 0 ? Math.floor(diff / 1000 / 60) % 60 : 0;
    const seconds = diff > 0 ? Math.floor(diff / 1000) % 60 : 0;
    this._out.days = days < 10 ? "0" + days : days;
    this._out.hours = hours < 10 ? "0" + hours : hours;
    this._out.minutes = minutes < 10 ? "0" + minutes : minutes;
    this._out.seconds = seconds < 10 ? "0" + seconds : seconds;
    this._out.daysTitle = CountdownTimer.declensionNum(days, [
      "days",
      "days",
      "days"
    ]);
    this._out.hoursTitle = CountdownTimer.declensionNum(hours, [
      "hours",
      "hours",
      "hours"
    ]);
    this._out.minutesTitle = CountdownTimer.declensionNum(minutes, [
      "minutes",
      "minutes",
      "minutes"
    ]);
    this._out.secondsTitle = CountdownTimer.declensionNum(seconds, [
      "seconds",
      "seconds",
      "seconds"
    ]);
    this._cbChange ? this._cbChange(this._out) : null;
    if (diff <= 0) {
      clearInterval(this._timerId);
      this._cbComplete ? this._cbComplete() : null;
    }
  }
}
if (document.querySelector(".timer")) {
  // console.log(Date.parse("2022-06-28T14:00:00+03:00"))
  // .timer-1 (на минуту)
  const elDays1 = document.querySelector(".timer__days");
  const elHours1 = document.querySelector(".timer__hours");
  const elMinutes1 = document.querySelector(".timer__minutes");
  const elSeconds1 = document.querySelector(".timer__seconds");
  const deadline1 = new Date(1656414000000);
  new CountdownTimer(
    deadline1,
    timer => {
      elDays1.textContent = timer.days;
      elHours1.textContent = timer.hours;
      elMinutes1.textContent = timer.minutes;
      elSeconds1.textContent = timer.seconds;
      elDays1.dataset.title = timer.daysTitle;
      elHours1.dataset.title = timer.hoursTitle;
      elMinutes1.dataset.title = timer.minutesTitle;
      elSeconds1.dataset.title = timer.secondsTitle;
    },
    () => {
      // document.querySelector(".timer__result").textContent = "Таймер завершился!"
    }
  );
}

import AOS from "aos";
AOS.init({
  once: true,
  disable: () => {
    const maxWidth = 576;
    return window.innerWidth < maxWidth;
  }
});
if (window.innerWidth > 576) {
  const mapSection = document.querySelector(".map");
  let base = 0.2;
  const revealMap = (entries, observer) => {
    const [entry] = entries;
    if (entry.intersectionRatio > 0) {
      entry.target.querySelector(".timeline").classList.add("animation");

      observer.unobserve(entry.target);
    }
  };
  const observer = new IntersectionObserver(revealMap, {
    root: null,
    threshold: 0.8
  });

  observer.observe(mapSection);
}
